import {Controller} from "stimulus"
import DomManipulator from "../dom/manipulator"

export default class extends Controller {
  connect() {
    $(this.element).bind('ajax:success', (event) => this.success())
  }

  success(){
    DomManipulator.remove(this.element)
  }
}