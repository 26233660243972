import {Controller} from "stimulus"
import FormSubmissionObserver from "../form/submissionObserver"
import FormSubmissionController from "./form_submission_controller"
import DOM from "../dom/manipulator"

export default class extends FormSubmissionController {
  connect() {
    super.connect()
  }

  onSaveSuccess(event) {
    super.onSaveSuccess(event);
    this.onGameOrderUpdated(event.detail[0])
  }


  onGameOrderUpdated(event) {
    if (event.gameBuying) {
      DOM.replace(this.gameBuyingSection, event.gameBuying)
    }
  }

  get gameBuyingSection(){
    return this.targets.find('gameBuying')
  }

}
