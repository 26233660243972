import AutoSubmittingController from '../controllers/components/auto_submitting_form_controller'
import _ from 'underscore'

export default class extends AutoSubmittingController {
  static targets = ['gameInput']

  submit() {
    const selectedOption = _.find(this.gameInputTarget.options, 'selected')
    if (!_.isUndefined(selectedOption)) {
      const gameId = selectedOption.value
      const form = this.formTarget
      form.action = form.action.replace(
        /games\/.*\/fans/,
        `games/${gameId}/fans`
      )
    }
    super.submit()
  }
}
