import { _ } from 'underscore'
import UIkit from 'uikit'
import FormSubmissionController from './form_submission_controller'
import DomManipulator from '../dom/manipulator'
import Ajax from '../http/ajax'

const priceExposureOptions = {
  None: '',
  Public: 'public_market',
  Sponsors: 'sponsors_only',
}

export default class extends FormSubmissionController {
  static targets = [
    'gameErrorMessage',
    'gameSelectionButton',
    'changeGameLink',
    'feedSelect',
    'saveAndAddAnotherButton',
    'pricePerUnit',
    'priceExposure',
    'priceExposureInput',
    'minimumMinutes',
    'saveButton',
  ]

  connect() {
    super.connect()

    $('.game-asset-selector:first-of-type').on('game-asset-selected', (event) =>
      this.onGameSelected(event)
    )

    if (this.data.get('show-success-on-start') === 'true') {
      //this.showSavedSuccessfully()
    }

    this.minimumMinutesChanged()
    DomManipulator.removeClass(this.saveButtonTarget, 'uk-button-large')

    this.minimumMinutesTarget.selectedIndex = 1
    if (parseInt(this.bulkSizeInput.value) > 1) {
      let option = _.find(this.minimumMinutesTarget.options, (option) => {
        return option.value == this.bulkSizeInput.value
      })
      this.minimumMinutesTarget.selectedIndex = option.index
      this.minimumMinutesTarget.disabled = true
    } else {
      this.minimumMinutesTarget.selectedIndex = this.minimumMinutesTarget.dataset.value
    }
  }

  submit() {}

  showSavedSuccessfully() {
    UIkit.notification({
      message: 'Listing saved',
      status: 'success',
      timeout: 5000,
      pos: 'bottom-left',
    })
  }

  onGameSelected(event) {
    let data = event.detail
    DomManipulator.hide(this.gameSelectionButtonTarget)
    DomManipulator.show(this.changeGameLinkTarget)

    this.gameDisplayText.innerHTML = data.gameName
    this.gameIdInput.value = data.gameId
    if (_.isElement(this.gameErrorMessageTarget)) {
      DomManipulator.hide(this.gameErrorMessageTarget)
    }

    this.updateAvailableFeeds(data)
    this.updatePriceRecommendation()
  }

  onFeedSelected() {
    this.updatePriceRecommendation()
  }

  updateAvailableFeeds(data) {
    Ajax.get(
      `/game_assets/available_feeds?game_id=${data.gameId}`,
      (responseData) => {
        const feedSelect = this.feedSelectTarget
        const feedCount = feedSelect.options.length
        let availableFeedCount = 0

        _.each(_.range(feedCount - 1, 0, -1), (index) => {
          feedSelect.options.remove(index)
        })

        _.each(responseData.feeds, (feed) => {
          const option = new Option(feed.name, feed.id)
          if (!feed.available) {
            option.setAttribute('disabled', 'disabled')
          } else {
            availableFeedCount++
          }
          feedSelect.options.add(option)
        })

        if (feedCount > 0) {
          feedSelect.removeAttribute('disabled')
        }
        if (availableFeedCount > 1) {
          DomManipulator.enable(this.saveAndAddAnotherButtonTarget)
        } else {
          DomManipulator.disable(this.saveAndAddAnotherButtonTarget)
        }
      },
      () => {},
      this
    )
  }

  updatePriceRecommendation() {
    const bulkSize = parseInt(this.bulkSizeInput.value)
    if (!_.isFinite(bulkSize)) {
      return
    }

    const gameId = this.gameIdInput.value
    if (_.isUndefined(gameId) || _.isEmpty(gameId)) {
      return
    }

    const selectedFeedOption = _.find(this.feedSelectTarget.options, 'selected')
    if (_.isUndefined(selectedFeedOption)) {
      return
    }
    const feedId = selectedFeedOption.value
    if (_.isUndefined(feedId) || _.isEmpty(feedId)) {
      return
    }

    Ajax.get(
      `/game_assets/price_recommendation?game_id=${gameId}&broadcast_feed_id=${feedId}&bulk_size=${bulkSize}`,
      (responseData) => {
        this.pricePerUnitTarget.value = responseData.price
      }
    )
  }

  sponsorsChanged(event) {
    if (event.currentTarget.value === priceExposureOptions.Sponsors) {
      DomManipulator.disable(this.priceExposureTarget)
      this.priceExposureInputTarget.checked = false
    } else {
      DomManipulator.enable(this.priceExposureTarget)
    }
  }

  pricingChanged(event) {
    if (event.currentTarget.value === 'bid') {
      this.bulkSizeInput.disabled = true
      this.minimumMinutesTarget.disabled = true
      if (this.bulkSizeInput.value > 1) {
        this.bulkSizeInput.selectedIndex = '1'
        this.unitBulkSizeChanged()
        this.minimumMinutesTarget.selectedIndex = '1'
      }
    } else {
      this.bulkSizeInput.disabled = false
      this.minimumMinutesTarget.disabled = false
    }
  }

  minimumMinutesChanged() {
    const minMinutes = parseInt(this.minimumMinutesTarget.value)
    if (minMinutes <= 0 || isNaN(minMinutes)) {
      return
    }

    let optionList = this.minutesInput.options
    let minutesDiff = minMinutes - parseInt(optionList[1].value)
    const bulkSize = parseInt(this.bulkSizeInput.value)

    if (minutesDiff > 0) {
      for (let index = 1; index < optionList.length; index++) {
        if (parseInt(optionList[index].value) < minMinutes) {
          optionList.remove(index)
          index--
        }
      }
    } else if (minutesDiff < 0) {
      let value = optionList[1].value - bulkSize
      while (value >= minMinutes) {
        let option = new Option(value.toString(), value.toString(), false)
        optionList.add(option, optionList[1])
        value = value - bulkSize
      }
    }
  }

  unitBulkSizeChanged() {
    const bulkSize = parseInt(this.bulkSizeInput.value)
    if (bulkSize <= 0 || isNaN(bulkSize)) {
      return
    }

    let minutesInputOptions = this.minutesInput.options
    let minMinutesOptions = this.minimumMinutesTarget.options

    // keep first item
    for (let index = minutesInputOptions.length - 1; index > 0; index--) {
      minutesInputOptions.remove(index)
    }

    for (let index = minMinutesOptions.length - 1; index > 0; index--) {
      minMinutesOptions.remove(index)
    }

    let start = bulkSize
    while (start <= 40) {
      minutesInputOptions.add(
        new Option(start.toString(), start.toString(), false)
      )
      minMinutesOptions.add(
        new Option(start.toString(), start.toString(), false)
      )
      start += bulkSize
    }

    if (bulkSize > 1) {
      minMinutesOptions.selectedIndex = 1
      DomManipulator.disable(this.minimumMinutesTarget)
    } else {
      DomManipulator.enable(this.minimumMinutesTarget)
    }

    this.bulkSizeDescription.innerHTML = `(${bulkSize}min)`
    this.updatePriceRecommendation()
  }

  get gameIdInput() {
    return this.targets.find('gameIdInput')
  }

  get gameDisplayText() {
    return this.targets.find('gameDisplayText')
  }

  get bulkSizeInput() {
    return this.targets.find('bulkSize')
  }

  get bulkSizeDescription() {
    return this.targets.find('bulkSizeDescription')
  }

  get bulkSizeDescriptionBid() {
    return this.targets.find('bulkSizeDescriptionBid')
  }

  get minutesInput() {
    return this.targets.find('minutes')
  }
}
