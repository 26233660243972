import {Controller} from "stimulus"
import {_} from 'underscore'
import FormSubmissionController from "./form_submission_controller"
import UIkit from 'uikit';


export default class extends FormSubmissionController {
  close() {
    UIkit.modal(this.modal).hide()
  }


  onSaveError(event) {
    //todo: will render entire page...maybe should not do that, but insert an error message in the modal box.
    super.onSaveError(event)
    this.close()
  }


  get modal() {
    return this.targets.find('modal')
  }
}