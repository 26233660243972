import { Controller } from 'stimulus'

export default class extends Controller {
  visit(event) {
    Turbolinks.visit(this.getUrl(event))
  }

  // Child component will override this
  getUrl() {
    // For now we are using current url
    // We can use this.data.get('url')
    return window.location.href
  }
}
