import {Controller} from "stimulus"
import {_} from 'underscore'
import Rails from "rails-ujs";
import DirectModelMediaUploadController from './direct_model_media_upload_controller'
import DomManipulator from "../dom/manipulator"

export default class extends DirectModelMediaUploadController {

  onServerSuccessEvent(event) {
    this.unsubscribeFromServerResponseEvents()

    this.renderSelectorState()

    var preview = event.originalEvent.detail[0].pageHtml
    $(this.root).trigger({type: "media-upload:end", preview: preview})
  }

  onServerFailureEvent(event) {
    this.unsubscribeFromServerResponseEvents()

    this.renderSelectorState()

    $(this.root).trigger({type: "media-upload:end", preview: null})

    //todo: should probably show upload failure message
  }

}