import {Controller} from "stimulus"
import {_} from 'underscore'
import * as Rails from "rails-ujs";
import UIkit from 'uikit';

export default class extends Controller {
  connect() {
  }

  //todo: not a good way to pass the games list. should seralize them to javascript and store in a dependency container. then referance from here.
  filterGames() {
    const homeTeamFilterText = this.homeTeamFilter.value.toUpperCase()
    const awayTeamFilterText = this.awayTeamFilter.value.toUpperCase()

    const lis = this.gameList.children

    for (var i = 1; i < lis.length; i++) {
      var homeTeam = lis[i].getAttribute('data-home-team').toUpperCase();
      var awayTeam = lis[i].getAttribute('data-away-team').toUpperCase();
      if ((awayTeam.indexOf(awayTeamFilterText) === 0 || awayTeamFilterText === '') &&
        (homeTeam.indexOf(homeTeamFilterText) === 0 || homeTeamFilterText === ''))
        lis[i].style.display = 'list-item';
      else
        lis[i].style.display = 'none';
    }

  }

  gameSelected(event) {
    let element = event.currentTarget

    UIkit.modal(this.gameModal).hide();

    this.raiseGameSelectedEvent(element);
  }

  raiseGameSelectedEvent(element) {
    const gameId = element.getAttribute('data-game-id')
    const gameName = element.innerHTML

    Rails.fire(this.root,"game-asset-selected",{gameId: gameId, gameName: gameName})
  }

  get homeTeamFilter() {
    return this.targets.find('homeTeamFilter')
  }

  get awayTeamFilter() {
    return this.targets.find('awayTeamFilter')
  }

  get gameList() {
    return this.targets.find('gameList')
  }

  get gameModal() {
    return this.targets.find('gameModal')
  }

  get root() {
    return this.targets.find('root')
  }
}