import { Controller } from 'stimulus'
import { _ } from 'underscore'
import Rails from 'rails-ujs'
import UIkit from 'uikit'
import DOM from '../dom/manipulator'

export default class extends Controller {
  static targets = ['buySection', 'spinnerSection', 'typeField', 'minMinutes']

  connect() {
    this.subscribeToFormEvents()

    this.gameOrderId = this.targets.find('gameOrderId').value
    this.requestConfirmationBeforeZeroMin = !window.location.pathname.includes(
      'games'
    )
    this.matchName = this.targets.find('matchName').value
    this.bulkUnitSize = parseInt(this.targets.find('bulkUnitSize').value)
    this.minMinutes = parseInt(this.minMinutesTarget.value)

    this.renderActivationState()

    this.debouncedSubmitHandler = _.debounce(() => {
      this.submitBookingForm()
    }, 1000)

    this.registerToSwitcherStateChanges()
  }

  registerToSwitcherStateChanges() {
    this.buttonsSection.addEventListener('beforeshow', () => {
      DOM.show(this.discountSection)
    })

    this.bidSection.addEventListener('beforeshow', () => {
      DOM.hide(this.discountSection)
    })

    this.buySectionTarget.addEventListener('beforeshow', () => {
      DOM.show(this.discountSection)
    })

    this.spinnerSectionTarget.addEventListener('beforeshow', () => {
      DOM.hide(this.discountSection)
    })
  }

  submitBookingForm() {
    Rails.fire(this.bookingForm, 'submit')
  }

  subscribeToFormEvents() {
    this.bookingForm.addEventListener('ajax:success', (event) => {
      this.onGameOrderUpdatedEvent(event.detail[0])
    })

    this.bookingForm.addEventListener('ajax:error', () => {
      // TODO: discus yonatan what to do here
    })
  }

  minusMinute(event) {
    UIkit.tooltip(this.plusButton, {
      delay: 30000,
      title: 'Press to add another minute',
    })

    var updatedMinutesCount
    if (
      !_.isUndefined(this.minMinutes) &&
      this.minMinutes >= this.bulkUnitSize
    ) {
      updatedMinutesCount =
        this.minutes.value == this.minMinutes
          ? 0
          : +this.minutes.value - this.bulkUnitSize
    } else {
      updatedMinutesCount = +this.minutes.value - this.bulkUnitSize
    }

    if (updatedMinutesCount >= 0) {
      this.requestMinutesBookingUpdateIfPermitted(event, updatedMinutesCount)
    }
  }

  buyNow(event) {
    this.typeFieldTarget.value = 'GameOrder'
    this.plusMinute(event, this.minMinutes)
  }

  plusMinute(event, minMinutes) {
    var updatedMinutesCount
    if (!_.isUndefined(minMinutes) && minMinutes >= this.bulkUnitSize) {
      updatedMinutesCount = minMinutes
    } else {
      updatedMinutesCount = +this.minutes.value + this.bulkUnitSize
    }

    if (updatedMinutesCount <= this.data.get('maxMinutes')) {
      UIkit.tooltip(event.target, {
        delay: 30000,
        title: 'No more minutes available',
      })
      this.requestMinutesBookingUpdateIfPermitted(event, updatedMinutesCount)
    } else {
      UIkit.tooltip(event.target, {
        delay: 0,
        pos: 'top-right',
        title: 'No more minutes available',
      })
      UIkit.tooltip(event.target).show()
    }
  }

  // cancelMinutes(event){
  //   this.requestMinutesBookingUpdateIfPermitted(event, 0);
  // }

  requestMinutesBookingUpdateIfPermitted(event, updatedMinutesCount) {
    if (updatedMinutesCount === 0 && this.requestConfirmationBeforeZeroMin) {
      this.executeOnUserConfirmation(event, updatedMinutesCount)
      return
    }

    this.requestMinutesBookingUpdate(event, updatedMinutesCount)
  }

  requestMinutesBookingUpdate(event, updatedMinutesCount) {
    if (updatedMinutesCount === 0) {
      this.stateSwitcher.show(3)
    }
    this.minutes.value = updatedMinutesCount

    this.debouncedSubmitHandler()
    $(event.target).trigger('requesting-game-minutes-booking')
  }

  executeOnUserConfirmation(event, updatedMinutesCount) {
    let confirmMsgBody =
      '<button  class="uk-modal-close-default" type="button" uk-close></button>' +
      '<p><b>Remove game</b></p><p>Are you sure you want to remove ' +
      this.matchName +
      ' from your campaign?</p>'
    var dialogOpt = {
      labels: { ok: 'Remove game', cancel: 'Keep it' },
      center: true,
    }

    let confirmCallback = $.proxy(function () {
      this.requestMinutesBookingUpdate(event, updatedMinutesCount)
    }, this)

    UIkit.modal.confirm(confirmMsgBody, dialogOpt).then(confirmCallback)
  }

  onGameOrderUpdatedEvent(event) {
    this.totalSection.innerHTML = event.gameOrderTotal

    if (event.gameBuying && this.gameBuyingSection != null) {
      DOM.replace(this.gameBuyingSection, event.gameBuying)
    }

    this.renderActivationState()

    Turbolinks.clearCache()
  }

  renderActivationState() {
    if (+this.minutes.value === 0) {
      $(this.minutesBooking).toggleClass('inactive')
    } else if ($(this.minutesBooking).hasClass('inactive')) {
      $(this.minutesBooking).toggleClass('inactive')
    }
  }

  get minutes() {
    return this.targets.find('minutes')
  }

  get bookingForm() {
    return this.targets.find('form')
  }

  get bookingButton() {
    return this.targets.find('book')
  }

  get minutesBooking() {
    return this.targets.find('minutesBooking')
  }

  get plusButton() {
    return this.targets.find('plusButton')
  }

  get totalSection() {
    return this.targets.find('total')
  }

  get discountSection() {
    return this.targets.find('discountSection')
  }

  get minutesBookingForm() {
    return this.targets.find('minutesBookingForm')
  }

  get gameBuyingSection() {
    return this.targets.find('gameBuying')
  }

  get stateSwitcher() {
    return UIkit.switcher(this.switcherElement)
  }

  get switcherElement() {
    return this.targets.find('switcher')
  }

  get buttonsSection() {
    return this.targets.find('buttonsSection')
  }

  // Continue here - show and hide discount according to current view
  get bidSection() {
    return this.targets.find('bidSection')
  }
}
