import { Controller } from 'stimulus'
import DomManipulator from '../dom/manipulator'

export default class extends Controller {
  static targets = [
    'stadsCheckbox',
    'stadsPrivacyCheckbox',
    'vrCheckbox',
    'errorMessage',
    'submitButton'
  ]

  connect() {
    this.bindToSubmit()
  }

  bindToSubmit() {
    this.submitButtonTarget.addEventListener('click', event => {
      if (
        this.stadsCheckboxTarget.checked &&
        (!this.hasVrCheckboxTarget || this.vrCheckboxTarget.checked)
      ) {
        DomManipulator.hide(this.errorMessageTarget)
      } else {
        event.preventDefault()
        DomManipulator.show(this.errorMessageTarget)
      }
    })
  }
}
