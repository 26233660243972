import {Controller} from "stimulus"
import UIkit from 'uikit';

export default class extends Controller {
  static targets = ["source"]

  copy(event) {
    event.preventDefault()

    const textArea = document.createElement("textarea")
    textArea.value = this.sourceTarget.innerText
    document.body.appendChild(textArea)
    textArea.select()
    document.execCommand("Copy")
    textArea.remove()

    UIkit.notification({
      message: "Copied to clipboard",
      timeout: 1000,
      pos: 'bottom-left'
    })
  }
}
