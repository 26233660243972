import GameFilterController from './game_filter_controller'
import Dom from '../dom/manipulator'

export default class extends GameFilterController {
  connect() {
    this.defaultToDate = this.toDate.value
  }

  filterItemSelected() {
    Dom.show(this.spinner)
    this.checkDefaultState()
    this.submit()
  }

  checkDefaultState() {
    var toDateValue = this.toDate.value
    if (
      (this.defaultToDate == toDateValue || toDateValue == '') &&
      this.endDate.value == ''
    ) {
      this.clearFilter()
    } else {
      this.updateFilterButton()
    }
  }

  updateFilterButton() {
    Dom.addClass(this.filterButton, 'item-selected')
    Dom.hide(this.chevronIcon)
    Dom.show(this.closeIcon)
  }

  resetFilterButton() {
    Dom.removeClass(this.filterButton, 'item-selected')
    Dom.show(this.chevronIcon)
    Dom.hide(this.closeIcon)
  }

  clearFilter() {
    Dom.show(this.spinner)
    this.toDate.value = this.defaultToDate
    this.endDate.value = ''
    this.resetFilterButton()
    this.submit()
  }

  get toDate() {
    return this.targets.find('toDate')
  }

  get endDate() {
    return this.targets.find('endDate')
  }
}
