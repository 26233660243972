import GameFilterController from './game_filter_controller'
import Dom from '../dom/manipulator'

export default class extends GameFilterController {
  connect() {
    this.checkFilterType(this.element.querySelector('[data-value="Date"]'))
  }

  updateFilterButton() {}

  updateSingleSelectItem(element) {
    var elements = document.getElementsByName(element.getAttribute('data-type'))
    // If no item select
    if (elements.length == 0) {
      this.addHiddenField(element)
      Dom.addClass(element, 'selected')
      Dom.html(this.selectedFilterName, `: ${element.dataset.value}`)
      this.updateFilterButton()
      return
    } // If unselect the same item
    if (elements[0].value == element.getAttribute('data-id')) {
      this.unSelectFilterItem()
      this.removeAllHiddenFields(element)
      Dom.html(this.selectedFilterName, '')
      this.resetFilterButton()
    } else {
      // If one item is already selected and select another item
      this.unSelectFilterItem()
      this.removeAllHiddenFields(element)
      Dom.addClass(element, 'selected')
      Dom.html(this.selectedFilterName, `: ${element.dataset.value}`)
      this.addHiddenField(element)
      this.updateFilterButton()
    }
  }
}
