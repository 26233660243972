/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import Rails from 'rails-ujs'
import * as ActiveStorage from 'activestorage'
import * as Sentry from '@sentry/browser'
import Chartkick from 'chartkick/dist/chartkick.esm'

// loads the Icon plugin
require.context('../images', true)
UIkit.use(Icons)

Chartkick.configure({ mapsApiKey: 'AIzaSyB7Rd7mpKBjGlG21g-jDxHhARqawbYvNLg' })

Rails.start()
ActiveStorage.start()
const application = Application.start()

if (process.env.JS_SENTRY_DSN !== undefined) {
  Sentry.init({ dsn: process.env.JS_SENTRY_DSN })

  application.handleError = (error, message, detail) => {
    console.warn(message, detail)
    console.error(error)
    Sentry.captureException(error)
  }
}

const context = require.context('../src/controllers', true, /\.js$/)
application.load(definitionsFromContext(context))

// loads the Icon plugin

//UIkit.use(Icons);

// components can be called from the imported UIkit reference
//UIkit.notification('Hello world.');

//$(
//   () => {
//     new GameOrderComponent()
//   }
// );
// TODO: try work with this = https://github.com/stimulusjs/stimulus

console.log(
  '\n' +
    '\n' +
    '  _________________________  ________    _________\n' +
    ' /   _____/\\__    ___/  _  \\ \\______ \\  /   _____/\n' +
    ' \\_____  \\   |    | /  /_\\  \\ |    |  \\ \\_____  \\ \n' +
    ' /        \\  |    |/    |    \\|    `   \\/        \\\n' +
    '/_______  /  |____|\\____|__  /_______  /_______  /\n' +
    '        \\/                 \\/        \\/        \\/ \n' +
    '\n'
)
