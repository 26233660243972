import { Controller } from 'stimulus'
import { _ } from 'underscore'
import Rails from 'rails-ujs'
import UIkit from 'uikit'
import DOM from '../dom/manipulator'
export default class extends Controller {
  static targets = ['form', 'package', 'button', 'spinner', 'title']

  connect() {
    this.subscribeToFormEvents()
  }

  deletePackage() {
    let confirmMsgBody =
      '<button  class="uk-modal-close-default" type="button" uk-close></button>' +
      '<p><b>Remove package</b></p><p>Are you sure you want to remove your package?</p>'
    var dialogOpt = {
      labels: { ok: 'Remove package', cancel: 'Keep it' },
      center: true
    }

    let confirmCallback = _.bind(function() {
      this.toggleButtonSpiner()
      Rails.fire(this.formTarget, 'submit')
    }, this)

    UIkit.modal.confirm(confirmMsgBody, dialogOpt).then(confirmCallback)
  }

  toggleButtonSpiner() {
    DOM.toggleClass(this.buttonTarget, 'uk-hidden')
    DOM.toggleClass(this.spinnerTarget, 'uk-hidden')
  }

  removeGamelist() {
    var games = document.getElementsByClassName('package-game')
    _.each(games, game => {
      DOM.toggleClass(game, 'uk-hidden')
    })
  }

  subscribeToFormEvents() {
    if (this.hasFormTarget) {
      this.formTarget.addEventListener('ajax:success', () => {
        DOM.toggleClass(this.packageTarget, 'uk-hidden')
        DOM.toggleClass(this.titleTarget, 'uk-hidden')
        this.removeGamelist()
      })

      this.formTarget.addEventListener('ajax:error', () => {
        this.toggleButtonSpiner()
      })
    }
  }
}
