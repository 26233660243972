import { Controller } from 'stimulus'
import _ from 'underscore'
import Dom from '../dom/manipulator'
import Ajax from '../http/ajax'
import UIkit from 'uikit'

export default class extends Controller {
  static targets = ['paymentMethod', 'bulkSize', 'minimumMinutes']

  connect() {
    this.formData = {}
  }

  changePaymentMethod() {
    this.handlePriceElements()

    this.handleCreateButtonState()
  }

  bulkSizeChanged(event) {
    const bulkSize = parseInt(event.currentTarget.value)
    const elements = document.querySelectorAll('.min-text')
    _.each(elements, (element) => {
      Dom.html(element, `(${bulkSize} min.)`)
    })

    let minutesInputOptions = this.minutesInput.options
    let minMinutesOptions = this.minimumMinutesTarget.options

    this.updateMinutesOptions(bulkSize, minutesInputOptions)
    this.updateMinutesOptions(bulkSize, minMinutesOptions)

    this.selectFirstIndex(this.minutesInput)
    this.selectFirstIndex(this.minimumMinutesTarget)

    bulkSize > 1
      ? Dom.disable(this.minimumMinutesTarget)
      : Dom.enable(this.minimumMinutesTarget)

    this.handlePriceElements()
  }

  updateMinutesOptions(bulkSize, optionList) {
    let start = bulkSize
    _.each(optionList, (list) => {
      if (list.value == start) {
        start += bulkSize
        Dom.show(list)
      } else {
        Dom.hide(list)
      }
    })
  }

  minimumMinutesChanged() {
    const minimumMinutes = parseInt(this.minimumMinutesTarget.value)

    let optionList = this.minutesInput.options
    let firstOption = optionList[optionList.selectedIndex]
    let minutesDifference = minimumMinutes - firstOption.value
    let bulkSize = this.bulkSizeTarget.value

    if (minutesDifference > 0) {
      _.each(optionList, (option) => {
        if (option.value < minimumMinutes) {
          Dom.hide(option)
        }
      })
    } else {
      _.each(optionList, (option) => {
        if (option.value >= minimumMinutes && option.value % bulkSize == 0) {
          Dom.show(option)
        }
      })
    }

    this.selectFirstIndex(this.minutesInput)
  }

  handlePriceElements() {
    const paymentMethod = this.paymentMethodTarget.value
    const bulkSize = this.bulkSizeTarget.value

    const hiddenElements = document.querySelectorAll(
      `.both:not(.${paymentMethod})`
    )
    const showElements = document.querySelectorAll(`.${paymentMethod}`)
    _.each(hiddenElements, (element) => {
      Dom.hide(element)
      element.querySelector('input').value = ''
    })
    _.each(showElements, (element) => {
      Dom.show(element)
      let input = element.querySelector("input[type='number']")

      if (input.name.includes('bid')) {
        input.value = input.getAttribute('value')
      } else {
        input.value = input.getAttribute('value') * bulkSize
      }
    })
  }

  gameSelected(event) {
    const rowIndex = event.target.closest('tr').rowIndex
    const theads = this.rowCells(0)
    _.each(this.skipOne(this.rowCells(rowIndex)), (cell) => {
      const checkbox = this.checkbox(cell)
      if (!checkbox.disabled) checkbox.checked = event.target.checked
      if (event.target.checked == true) {
        this.addFormData(checkbox)
        const row = this.findUnheckedRow(this.skipOne(this.table.rows), cell)
        if (!row) this.setCheckbox(this.rowCells(0)[cell.cellIndex], true)
      } else {
        this.setCheckbox(theads[cell.cellIndex], false)
        this.removeFormData(checkbox)
      }
    })
    this.setMatches()
  }

  feedSelected(event) {
    var cellIndex = event.target.closest('th').cellIndex

    _.each(this.skipOne(this.table.rows), (row) => {
      var checkbox = this.checkbox(row.cells[cellIndex])
      if (!checkbox.disabled) checkbox.checked = event.target.checked
      if (checkbox.checked) {
        this.addFormData(checkbox)
      } else {
        this.removeFormData(checkbox)
      }
      if (this.isCellDisabled(row.cells[0])) {
        return true
      }
      var cell = this.findCheckedCell(this.skipOne(row.cells))
      if (!cell) this.setCheckbox(row.cells[0], false)
      else this.setCheckbox(row.cells[0], true)
    })
    this.setMatches()
  }

  gameFeedSelected(event) {
    var rowIndex = event.target.closest('tr').rowIndex
    var cells = this.rowCells(rowIndex)
    var currentCell = event.target.closest('td')
    var theads = this.rowCells(0)
    if (event.target.checked) {
      this.setCheckbox(cells[0], true)
      this.addFormData(event.currentTarget)
      this.setCheckbox(cells[0], true)
      var row = this.findUnheckedRow(this.skipOne(this.table.rows), currentCell)
      if (!row) this.setCheckbox(theads[currentCell.cellIndex], true)
    } else {
      this.removeFormData(event.currentTarget)
      var cell = this.findCheckedCell(this.skipOne(cells))
      this.setCheckbox(theads[currentCell.cellIndex], false)
      if (!cell) {
        this.setCheckbox(cells[0], false)
      }
    }
    this.setMatches()
  }

  setMatches() {
    var matches = _.filter(this.skipOne(this.table.rows), (row) => {
      return row.querySelector('.game-feed-checkbox:not([disabled]):checked')
    })
    if (matches.length == 0) {
      this.nextButton.disabled = true
      this.matches.innerHTML = matches.length + ' Match Selected'
    } else if (matches.length > 1) {
      this.nextButton.disabled = false
      this.matches.innerHTML = matches.length + ' Matches Selected'
    } else {
      this.nextButton.disabled = false
      this.matches.innerHTML = matches.length + ' Match Selected'
    }
  }

  selectFirstIndex(optionList) {
    optionList.selectedIndex = optionList.querySelector(':not([hidden])').index
  }

  findCheckedCell(cells) {
    return _.find(cells, (cell) => {
      const checkbox = this.checkbox(cell)
      return checkbox.checked && !checkbox.disabled
    })
  }

  findUnheckedRow(rows, cell) {
    return _.find(rows, (row) => {
      return this.checkbox(row.cells[cell.cellIndex]).checked == false
    })
  }

  skipOne(element) {
    return _.rest(element)
  }

  isCellDisabled(cell) {
    return this.checkbox(cell).disabled
  }
  setCheckbox(cell, value) {
    this.checkbox(cell).checked = value
  }

  checkbox(cell) {
    return cell.querySelector("input[type='checkbox']")
  }

  addFormData(element) {
    const formKey = this.getformDataKey(element)
    if (!this.formData[formKey]) {
      this.formData[formKey] = element.getAttribute('data-game')
    }
  }

  removeFormData(element) {
    delete this.formData[this.getformDataKey(element)]
  }

  getformDataKey(element) {
    return `${element.getAttribute('data-name')}[game_id]`
  }
  combineFormData() {
    this.combineData = new FormData(this.form)
    _.each(this.formData, (value, key) => {
      this.combineData.append(key, value)
    })
  }

  rowCells(rowIndex) {
    return this.table.rows[rowIndex].cells
  }

  addHover(event) {
    this.hover(event, 'add')
  }

  removeHover() {
    this.hover(event, 'remove')
  }

  hover(event, type) {
    var cell = event.target
    var cellIndex = cell.cellIndex
    var rowIndex = cell.parentElement.rowIndex
    var game = this.rowCells(rowIndex)[0]
    // Handle row hover state
    _.times(cellIndex, function () {
      if (type === 'add') {
        Dom.addClass(cell, 'hover')
        Dom.addClass(game, 'hover')
      } else {
        Dom.removeClass(cell, 'hover')
        Dom.removeClass(game, 'hover')
      }
      cell = cell.previousSibling
    })
    // Handle column hover state
    _.times(rowIndex, (row) => {
      var currentCell = this.rowCells(row)[cellIndex]
      if (type === 'add') {
        currentCell.classList.add('hover')
      } else {
        currentCell.classList.remove('hover')
      }
    })
  }

  handleCreateButtonState() {
    const inputsContainers = document.querySelectorAll('.both:not([hidden])')
    const createButton = document.getElementById('createButton')
    const blankInput = _.find(inputsContainers, (element) => {
      var value = parseInt(element.querySelector('input').value)
      return !value || value < 0
    })
    if (blankInput) {
      createButton.disabled = true
    } else {
      createButton.disabled = false
    }
  }

  getPricingHtml() {
    Dom.show(this.spinner)
    this.combineFormData()
    Ajax.post(
      `games_assets_bulk/pricing_details?team_id=${this.teamInput.value}`,
      this.combineData,
      (response) => {
        Dom.hide(this.spinner)
        Dom.hide(this.step1)
        Dom.hide(this.nextButton)
        Dom.show(this.createButton)
        Dom.toggleClass(this.selectPrice, 'uk-text-secondary')
        Dom.toggleClass(this.selectMatch, 'uk-text-secondary')
        Dom.addClass(this.selectMatch, 'uk-text-muted')
        Dom.append(response.html, this.form)
      },
      (err) => {
        Dom.hide(this.spinner)
        console.log(err)
      },
      this
    )
  }

  submit() {
    Dom.show(this.spinner)
    this.combineFormData()
    Ajax.post(
      `games_assets_bulk/?team_id=${this.teamInput.value}`,
      this.combineData,
      (response) => {
        Dom.hide(this.spinner)
        UIkit.modal(this.modal).hide()
        Dom.html(document.querySelector('#assets-list'), response.html)
      },
      (err) => {
        Dom.hide(this.spinner)
        Dom.html(document.querySelector('.uk-modal-full'), '')
        console.log(err)
      },
      this
    )
  }

  getModal() {
    Dom.show(this.spinner)
    UIkit.dropdown(this.dropdown).hide()
    Ajax.get(
      `games_assets_bulk/new?team_id=${this.dropdown.getAttribute(
        'data-teamId'
      )}`,
      (response) => {
        Dom.html(this.modal, response.html)
        UIkit.modal(this.modal).show()
        Dom.hide(this.spinner)
      },
      (err) => {
        Dom.hide(this.spinner)
        console.log(err)
      },
      this
    )
  }

  get spinner() {
    return document.querySelector('.spinner')
  }
  get dropdown() {
    return this.targets.find('dropdown')
  }
  get teamInput() {
    return this.targets.find('teamInput')
  }
  get nextButton() {
    return this.targets.find('nextButton')
  }
  get createButton() {
    return this.targets.find('createButton')
  }
  get table() {
    return this.targets.find('table')
  }
  get thead() {
    return this.targets.find('thead')
  }
  get matches() {
    return this.targets.find('matches')
  }
  get form() {
    return this.targets.find('form')
  }
  get step1() {
    return this.targets.find('step1')
  }
  get minutesInput() {
    return this.targets.find('minutes')
  }
  get selectMatch() {
    return this.targets.find('selectMatch')
  }
  get selectPrice() {
    return this.targets.find('selectPrice')
  }
  get modal() {
    return document.querySelector('.uk-modal-full')
  }
}
