import {Controller} from "stimulus"

import DomManipulator from "../dom/manipulator"

const PricingOptions = {
  None: '',
  Fixed: 'fixed',
  Bid: 'bid',
  Both: 'both'
}

export default class extends Controller {
  static targets = ['selector', 'fixedPriceInputsSection', 'bidInputsSection']


  connect() {
    this.updateFieldsView()
  }

  pricingChanged(event) {
    this.updateFieldsView()
  }

  updateFieldsView() {
    switch (this.selectorTarget.options[this.selectorTarget.selectedIndex].value) {
      case PricingOptions.None:
        DomManipulator.hide(this.fixedPriceInputsSectionTarget)
        DomManipulator.hide(this.bidInputsSectionTarget)
        break
      case PricingOptions.Fixed:
        DomManipulator.show(this.fixedPriceInputsSectionTarget)
        DomManipulator.hide(this.bidInputsSectionTarget)
        break
      case PricingOptions.Bid:
        DomManipulator.hide(this.fixedPriceInputsSectionTarget)
        DomManipulator.show(this.bidInputsSectionTarget)
        break
      case PricingOptions.Both:
        DomManipulator.show(this.fixedPriceInputsSectionTarget)
        DomManipulator.show(this.bidInputsSectionTarget)
        break
    }
  }
}