import {Controller} from "stimulus"
import {_} from 'underscore'

export default class extends Controller {
  connect() {
  }

  selectionChanged(event) {
    event.preventDefault()
    this.form.submit()
  }

  get form() {
    return this.targets.find('form')
  }
}