import { Controller } from 'stimulus'
import Dom from '../../dom/manipulator'
import UIkit from 'uikit'
import { _ } from 'underscore'

/**
 * Highlight controller - can scroll to elements and make a highlight effect on them.
 */
export default class extends Controller {
  connect() {
    if (document.documentElement.hasAttribute('data-turbolinks-preview')) {
      return
    }
    UIkit.util.ready(() => this.highlight())
  }

  initialize() {
    this.highlightTarget = this.data.get('highlightTarget')

    this.highlight = _.once(
      _.wrap(_.bind(this.highlight, this), func => _.delay(func, 250))
    )
  }

  highlight() {
    if (_.isNull(this.highlightTarget)) {
      return
    }

    const target = Dom.find(this.highlightTarget)
    if (!_.isElement(target)) {
      return
    }

    this.cleanupElement = target
    UIkit.util.on(this.element, 'scrolled', function() {
      Dom.addClass(target, 'animate-highlight')
      _.delay(() => Dom.removeClass(target, 'animate-highlight'), 5000)
      this.cleanupElement = null
    })

    UIkit.scroll(this.element, {
      duration: 500,
      offset: document.documentElement.clientHeight / 2 - 100
    }).scrollTo(target)
  }

  disconnect() {
    if (_.isUndefined(this.cleanupElement) || _.isNull(this.cleanupElement)) {
      return
    }

    Dom.removeClass(this.cleanupElement, 'animate-highlight')
  }
}
