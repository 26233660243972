import { Controller } from 'stimulus'
import Ajax from '../../http/ajax'
import Dom from '../../dom/manipulator'
import UIkit from 'uikit'
import { _ } from 'underscore'

export default class extends Controller {
  static targets = ['spinner', 'container']

  connect() {
    this.contentLoaded = false
    this.contentLoading = false
  }

  initialize() {
    this.url = this.data.get('url')
    this.toggleTarget = this.data.get('toggle-target')
  }

  loadContent(event) {
    if (this.contentLoading) {
      return
    }
    this.contentLoading = true
    const button = event.currentTarget
    Dom.addClass(button, 'uk-disabled')

    const toggleAction = _.once(() => {
      UIkit.toggle(button, {
        target: this.toggleTarget,
        animation: 'uk-animation-fade'
      }).toggle()
      Dom.removeClass(button, 'uk-disabled')
      this.contentLoading = false
    })

    if (this.contentLoaded) {
      toggleAction()
      return
    }

    Dom.show(this.spinnerTarget)
    Ajax.get(
      this.url,
      response => {
        this.contentLoaded = true
        this.content = response.html
        this.containerTarget.innerHTML = this.content
        Dom.hide(this.spinnerTarget)
        toggleAction()
      },
      () => {
        this.contentLoading = false
        Dom.removeClass(button, 'uk-disabled')
        Dom.hide(this.spinnerTarget)
      }
    )
  }
}
