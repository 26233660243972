import {Controller} from "stimulus"
import {_} from 'underscore'
import FormSubmissionController from "./form_submission_controller"
import UIkit from 'uikit';
import DomManipulator from "../dom/manipulator"



export default class extends Controller {
  connect() {
    // figure out why this is not working
    // const form = DomManipulator.find("#"+this.data.get("form-id"))
    // form.addEventListener("media-upload:end", (event) => this.success(event))
    $("#"+this.data.get("form-id")).bind('media-upload:end', $.proxy(this.success, this))

  }

  success(event){
    DomManipulator.prepend(event.preview, this.element)
  }
}