import { _ } from 'underscore'
import FormSubmissionController from './form_submission_controller'

export default class extends FormSubmissionController {
  static targets = ['advertiserIdInput', 'advertiserCategoryInput']

  advertiserChanged(event) {
    const input = event.target
    if (input.value === '') {
      this.clearValidations(input)
      return
    }
    const datalist = input.list

    if (datalist != null) {
      const relevantAdvertiser = _.find(
        datalist.options,
        option => input.value === option.value
      )
      let advertiserId = null
      let advertiserCategory = null
      if (relevantAdvertiser !== undefined) {
        advertiserId = relevantAdvertiser.getAttribute('data-id')
        advertiserCategory = relevantAdvertiser.getAttribute('data-category')

        this.advertiserIdInputTarget.value = advertiserId
        this.advertiserCategoryInputTarget.value = advertiserCategory

        this.clearValidations(input)
      } else {
        //input.setCustomValidity('Please select a valid value.');
      }
    }
  }

  clearValidations(input) {
    input.setCustomValidity('')
  }
}
