import Dom from '../dom/manipulator'
import MediaUploadController from './model_media_upload_base_controller'

export default class extends MediaUploadController {
  static targets = ['advertiserLogoPreview']

  initData() {
    super.initData()

    var modelFormId = this.data.get('modelForm')
    this.modelFormElement = $('#' + modelFormId)
  }

  onDirectUploadFinished(event) {
    super.onDirectUploadFinished(event)

    this.cloneMediaValueToModelForm()
  }

  remove() {
    this.removeSelectedMediaValue()

    this.markModelFormMediaAsChanged()

    this.renderSelectorState()
  }

  removeSelectedMediaValue() {
    Dom.remove(this.advertiserLogoPreviewTarget)
    this.clearMediaValueInForm(this.modelFormElement)
    this.clearMediaValueInForm(this.mediaFormElement)
  }

  clearMediaValueInForm(formElement) {
    let mediaValueElement = $(formElement).find(
      "input[type=hidden][name='" + this.mediaInputElementName + "']"
    )
    mediaValueElement.remove()
  }

  cloneMediaValueToModelForm() {
    this.clearMediaValueInForm(this.modelFormElement)

    var mediaInputElement = $(this.mediaFormElement)
      .find("input[name='" + this.mediaInputElementName + "']")
      .first()
    $(this.modelFormElement).append(mediaInputElement.clone())
  }

  markModelFormMediaAsChanged() {
    $(this.modelFormElement).find("input[name='media-changed']").val('true')
  }
}
