import {Controller} from "stimulus"
import {_} from 'underscore'
import InputValidationCleaner from "../form/inputValidationCleaner"

export default class extends Controller {
  connect() {
    $(this.form).on('ajax:before', $.proxy(this.onBeforeSubmit, this))
    $(this.form).on('ajax:error', $.proxy(this.onSaveError, this))
    $(this.form).on('ajax:success', $.proxy(this.onSaveSuccess, this))

    $("body").bind('direct-upload:start', $.proxy(this.disableSave, this))
    $("body").bind('direct-upload:error', $.proxy(this.enableSave, this))
    $("body").bind('direct-upload:end', $.proxy(this.enableSave, this))
    new InputValidationCleaner().bind(this.form)
  }

  onBeforeSubmit() {
    this.disableSave()
    this.spinner.classList.toggle('uk-hidden')
  }

  onSaveSuccess(event){
  }

  onSaveError(event) {
    var originalEvent = event.originalEvent
    let pageHtml = originalEvent.detail[0].pageHtml;
    //todo: should handle other error types except 422, which will not have an html to display

    document.getElementById("page-content").innerHTML = pageHtml
    document.getElementById("page-content").parentNode.scrollTo({top:0})
  }

  submit() {
    this.submitButton.click()
  }

  enableSave() {
    this.saveButton.disabled = false
  }

  disableSave() {
    this.saveButton.disabled = true
  }

  get spinner() {
    return this.targets.find('spinner')
  }

  get saveButton() {
    return this.targets.find('saveButton')
  }

  get form() {
    return this.targets.find('form')
  }

  get submitButton() {
    return this.targets.find('submitButton')
  }

}
