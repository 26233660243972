import { Controller } from 'stimulus'
import Rails from 'rails-ujs'
import UIkit from 'uikit'
import Dom from '../dom/manipulator'

export default class extends Controller {
  beforePageLeaveHandler = (event) => this.beforePageLeave(event)
  discardDefaultCampaignGamesConfirmation = false

  connect() {
    this.isDefaultNewCampaign =
      this.targets.find('isDefaultNewCampaign').value == 'true'
    this.totalBookedMinutes = parseInt(
      this.targets.find('totalBookedMinutes').value
    )
    this.campaignPath = this.targets.find('continueToCampaignLink').value

    this.subscribeToEvents()

    this.renderStickySummaryState()
  }

  subscribeToEvents() {
    this.listenToPageLeave()

    Rails.delegate(
      this.element,
      'div.campaign-games',
      'booking:updated',
      () => {
        this.onGameBookingUpdatedEvent(event.detail[0])
      }
    )

    Rails.delegate(
      this.element,
      'form[data-target="game-order.form"]',
      'ajax:success',
      () => {
        this.onGameBookingUpdatedEvent(event.detail[0])
      }
    )

    Rails.delegate(
      this.element,
      'form[data-target="bid-form.form"]',
      'ajax:success',
      () => {
        this.onGameBookingUpdatedEvent(event.detail[0])
      }
    )

    $('body').bind('modal-open:createCampaignModal', () =>
      this.stopListenToPageLeave()
    )

    $('body').bind('modal-open:createCampaignModalCheckout', () =>
      this.stopListenToPageLeave()
    )

    $('body').bind('campaign-creation:cancelled', () =>
      this.listenToPageLeave()
    )

    this.renderStickySummaryState()
  }

  onGameBookingUpdatedEvent(event) {
    this.totalBookedMinutes = event.totalMinutes
    if (this.totalMintuesPrice) {
      Dom.html(this.totalMintuesPrice, event.totalMintuesPrice)
    } else {
      Dom.html(this.continueAction, event.buttonText)
    }
    if (this.orderList) {
      Dom.html(this.orderList, event.orderList)
    }
    $('#total').html(event.totalCost)

    this.renderStickySummaryState()
  }

  renderStickySummaryState() {
    //todo: use stimulus and dom instead of jquery

    if (this.totalBookedMinutes > 0) {
      $('#no-minutes-text').hide()
      $('#total').show()
      this.continueAction.disabled = false

      if (this.subtotalSummary) {
        Dom.hide(this.emptyState)
        Dom.show(this.orderList)
        Dom.show(this.subtotalSummary)
      }
    } else {
      $('#total').hide()
      $('#no-minutes-text').show()
      this.continueAction.disabled = this.isDefaultNewCampaign
      if (this.subtotalSummary) {
        Dom.hide(this.subtotalSummary)
        Dom.hide(this.orderList)
        Dom.show(this.emptyState)
      }
    }
  }

  onContinueToCampaignClick() {
    Turbolinks.visit(this.campaignPath)
  }

  beforePageLeave(event) {
    this.stopListenToPageLeave()

    let destinationUrl = event.data.url

    if (
      this.isDefaultCampaignWithUnsavedBookedMinutes() &&
      !this.isNavigatingToSaveCampaign(destinationUrl)
    ) {
      event.preventDefault()

      this.suspendedNavigationUrl = destinationUrl

      this.suggestSavingDefaultCampaign()
    }
  }

  listenToPageLeave() {
    document.addEventListener(
      'turbolinks:before-visit',
      this.beforePageLeaveHandler
    )
  }

  stopListenToPageLeave() {
    document.removeEventListener(
      'turbolinks:before-visit',
      this.beforePageLeaveHandler
    )
  }

  isNavigatingToSaveCampaign(destinationUrl) {
    return destinationUrl.endsWith(this.continueToCampaignLink)
  }

  isDefaultCampaignWithUnsavedBookedMinutes() {
    return this.isDefaultNewCampaign && this.totalBookedMinutes > 0
  }

  suggestSavingDefaultCampaign() {
    this.discardDefaultCampaignGamesConfirmation = false

    let confirmMsgBody =
      '<button  class="uk-modal-close-default" type="button" uk-close></button>' +
      '<p><b>Review changes</b></p><p>You have selected games. Do you want to create a campaign and save them?</p>'

    var dialogOpt = {
      labels: { ok: 'Create campaign', cancel: 'Discard games' },
      center: true,
    }

    UIkit.modal
      .confirm(confirmMsgBody, dialogOpt)
      .then(
        $.proxy(this.onSaveCampaignDialogConfirm, this),
        $.proxy(this.onSaveCampaignDialogClose, this)
      )

    $('.uk-modal-dialog button.uk-modal-close').click(
      $.proxy(this.onDiscardDefaultCampaignGames, this)
    )
  }

  onDiscardDefaultCampaignGames() {
    this.discardDefaultCampaignGamesConfirmation = true
  }

  onSaveCampaignDialogClose() {
    if (this.discardDefaultCampaignGamesConfirmation) {
      Turbolinks.visit(this.suspendedNavigationUrl)
      return
    }

    this.listenToPageLeave()
  }

  onSaveCampaignDialogConfirm() {
    UIkit.modal($('#createCampaignModal')).show()
  }

  get continueAction() {
    return this.targets.find('continueAction')
  }

  get total() {
    return this.targets.find('total')
  }

  get noMinutesText() {
    return this.targets.find('noMinutesText')
  }

  get totalMintuesPrice() {
    return this.targets.find('totalMintuesPrice')
  }

  get orderList() {
    return this.targets.find('orderList')
  }

  get emptyState() {
    return this.targets.find('emptyState')
  }

  get subtotalSummary() {
    return this.targets.find('subtotalSummary')
  }
}
