import { Controller } from 'stimulus'
import Dom from '../dom/manipulator'
import UIkit from 'uikit'

export default class extends Controller {
  static targets = ['type', 'popup']

  connect() {
    let modal = UIkit.modal(this.popupTarget)
    setTimeout(function () {
      modal.show()
    }, 1500)
  }

  toggleType(event) {
    if (event.target.value === 'advertiser') {
      Dom.removeClass(this.typeTarget, 'uk-disabled')
    } else {
      Dom.addClass(this.typeTarget, 'uk-disabled')
    }
  }
}
