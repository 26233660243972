import { Controller } from 'stimulus'
import { _ } from 'underscore'
import DomManipulator from '../dom/manipulator'

export default class extends Controller {
  static targets = [
    'newThresholdTemplate',
    'discountThresholdUnits',
    'discountThresholdPercentage',
    'newThresholdTemplate',
    'thresholdList',
    'discountThresholdErrorMessage',
    'unitInputs',
    'percentageInputs',
  ]

  connect() {
    this.maxUnits = []
    this.maxPercentage = []
    _.each(this.unitInputsTargets, (numberInput) =>
      this.maxUnits.push(parseInt(numberInput.value))
    )
    _.each(this.percentageInputsTargets, (numberInput) =>
      this.maxPercentage.push(parseInt(numberInput.value))
    )
    this.filterOptionsForDiscount()
  }

  addDiscountThreshold() {
    const units = parseInt(this.discountThresholdUnitsTarget.value)
    const percentage = parseInt(this.discountThresholdPercentageTarget.value)

    if (isNaN(units) || isNaN(percentage) || units <= 0 || percentage <= 0) {
      DomManipulator.show(this.discountThresholdErrorMessageTarget)
      return
    }

    this.discountThresholdUnitsTarget.value = ''
    this.discountThresholdPercentageTarget.value = ''
    DomManipulator.hide(this.discountThresholdErrorMessageTarget)

    let newItemTemplate = this.newThresholdTemplateTarget.innerText
      .replace(/{{units}}/g, units.toString())
      .replace(/{{percentage}}/g, percentage.toString())

    DomManipulator.append(newItemTemplate, this.thresholdListTarget)

    this.maxUnits.push(units)
    this.maxPercentage.push(percentage)
    this.filterOptionsForDiscount()
  }

  filterOptionsForDiscount() {
    _.each(this.discountThresholdUnitsTarget.options, (option) => {
      if (
        !_.isEmpty(this.maxUnits) &&
        _.last(this.maxUnits) >= parseInt(option.value)
      ) {
        DomManipulator.hide(option)
      } else {
        DomManipulator.show(option)
      }
    })

    _.each(this.discountThresholdPercentageTarget.options, (option) => {
      if (
        !_.isEmpty(this.maxPercentage) &&
        _.last(this.maxPercentage) >= parseInt(option.value)
      ) {
        DomManipulator.hide(option)
      } else {
        DomManipulator.show(option)
      }
    })
  }

  deleteDiscountThreshold(event) {
    const target = event.target.closest('button')
    const units = parseInt(target.getAttribute('data-units'))
    const percentage = parseInt(target.getAttribute('data-percentage'))

    this.maxPercentage = _.without(this.maxPercentage, percentage)
    this.maxUnits = _.without(this.maxUnits, units)

    const element = target.closest('li')
    const destroyer = element.querySelector(
      'input[data-target="discount-threshold.destroyer"]'
    )

    if (destroyer) {
      destroyer.value = true
      DomManipulator.hide(element)
    } else {
      DomManipulator.remove(element)
    }

    this.filterOptionsForDiscount()
  }
}
