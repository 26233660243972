import { Controller } from 'stimulus'
import FormSubmissionObserver from '../form/submissionObserver'
import DomManipulator from '../dom/manipulator'

export default class extends Controller {
  static targets = ['approveForm', 'rejectForm']

  connect() {
    if (this.hasApproveFormTarget) {
      // only relevant for pending
      new FormSubmissionObserver().bind(this.approveFormTarget, () => {
        const pendingCountElem = document.getElementById('pendingCount')
        DomManipulator.html(pendingCountElem, +pendingCountElem.innerHTML - 1)
      })
    }

    if (this.hasRejectFormTarget) {
      new FormSubmissionObserver().bind(this.rejectFormTarget, event => {
        const pendingCountElem = document.getElementById('pendingCount')
        DomManipulator.html(pendingCountElem, +pendingCountElem.innerHTML - 1)
        DomManipulator.prepend(event.detail[0].rejectedItem, $('#declinedList'))
      })
    }
  }
}
