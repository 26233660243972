import { Controller } from 'stimulus'
import Dom from '../dom/manipulator'
import _ from 'underscore'
import UIkit from 'uikit'
export default class extends Controller {
  connect() {
    this.visiableCountires = 0
    if (this.collapseFeed) {
      this.beforeCollapse = this.beforeCollapse.bind(this)
      this.collapseFeed.addEventListener('beforehide', this.beforeCollapse)
    }
  }

  beforeCollapse() {
    var expandedFeed = this.element.querySelector(
      '.toggle-details:not([hidden])'
    )
    if (this.hideFeedAction.hidden == false && expandedFeed) {
      Dom.hide(expandedFeed)
      UIkit.toggle(this.hideFeedAction).toggle()
    }
  }

  filterData() {
    const searchText = this.selectedValue.toUpperCase()
    const tableBody = this.tableBody
    if (searchText == '') {
      Dom.html(this.countryName, 'Where is your target audience?')
      this.resetTableData(tableBody)
    } else {
      Dom.html(this.countryName, this.selectedValue)
      const childs = tableBody.children
      Dom.hide(tableBody.lastChild)
      _.each(childs, (child) => {
        if (child.getAttribute('data-value')) {
          var listItem = child.getAttribute('data-value').toUpperCase()
          if (listItem.indexOf(searchText) === 0) {
            Dom.show(child)
          } else {
            Dom.hide(child)
          }
        }
      })
      Dom.show(tableBody.firstChild)
    }
  }

  resetTableData(tableBody) {
    Dom.show(tableBody.lastChild)
    var hideContries = tableBody.querySelectorAll('.hide-contries')
    var showContries = tableBody.querySelectorAll('.show-contries')
    _.each(hideContries, (child) => {
      Dom.hide(child)
    })
    _.each(showContries, (child) => {
      Dom.show(child)
    })
  }

  expandContries() {
    let hideContries = this.tableBody.querySelectorAll('.hide-contries[hidden]')
    let countries = Array.from(hideContries).slice(0, 10)
    _.each(countries, (child) => {
      Dom.show(child)
    })
    this.visiableCountires += 10
    let remainingContries = hideContries.length - this.visiableCountires
    if (remainingContries <= 0) {
      Dom.hide(this.expandContriesButton)
      Dom.show(this.collapseContriesButton)
    } else {
      Dom.html(this.expandContriesButton, `${remainingContries} more countries`)
    }
  }
  collapseContries() {
    this.visiableCountires = 0
    Dom.show(this.expandContriesButton)
    Dom.hide(this.collapseContriesButton)
    let countries = this.tableBody.querySelectorAll('.hide-contries')
    Dom.html(this.expandContriesButton, `${countries.length} more countries`)
    _.each(countries, (child) => {
      Dom.hide(child)
    })
  }
  showFeed(event) {
    this.showElement = event.currentTarget.getAttribute('data-id')
  }

  hideFeed() {
    Dom.hide(document.getElementsByClassName(this.showElement)[0])
  }

  get moreButton() {
    return this.targets.find('moreButton')
  }
  get selectedValue() {
    return this.targets.find('selectedValue').value
  }
  get countryName() {
    return this.targets.find('countryName')
  }
  get expandContriesButton() {
    return this.targets.find('expandContriesButton')
  }
  get collapseContriesButton() {
    return this.targets.find('collapseContriesButton')
  }
  get tableBody() {
    return this.targets.find('tableBody')
  }
  get collapseFeed() {
    return this.targets.find('collapseFeed')
  }
  get hideFeedAction() {
    return this.targets.find('hideFeedAction')
  }
}
