import { Controller } from 'stimulus'
import Ajax from '../http/ajax'

export default class extends Controller {
  static targets = ['form', 'visibilityText']

  visibilityChanged(event) {
    var data = new FormData(this.formTarget)
    var url = this.formTarget.action
    const visibleTo = event.currentTarget

    Ajax.post(
      url,
      data,
      success => {
        console.log(success)
      },
      err => {
        console.log(err)
        if (visibleTo.options[1].selected) {
          let option = visibleTo.options[0]
          option.selected = true
          this.visibilityTextTarget.textContent = option.text
        } else {
          let option = visibleTo.options[1]
          option.selected = true
          this.visibilityTextTarget.textContent = option.text
        }
      },
      this
    )
  }
}
