import { Controller } from 'stimulus'
import { _ } from 'underscore'
import DomManipulator from '../dom/manipulator'

export default class extends Controller {
  static targets = [
    'newCategoryPriceTemplate',
    'categoryInputs',
    'categoryPriceCategory',
    'categoryPricePrice',
    'categoryPriceErrorMessage',
    'categoryPricesList'
  ]

  connect() {
    this.categoriesWithPrice = []
    _.each(this.categoryInputsTargets, categoryInput =>
      this.categoriesWithPrice.push(categoryInput.value)
    )
    this.filterOptionsForCategoryPrice()
  }

  filterOptionsForCategoryPrice() {
    _.each(this.categoryPriceCategoryTarget.options, option => {
      if (_.indexOf(this.categoriesWithPrice, option.value) >= 0) {
        DomManipulator.hide(option)
      } else {
        DomManipulator.show(option)
      }
    })
  }

  addCategoryPrice() {
    const category = this.categoryPriceCategoryTarget.value
    const price = parseInt(this.categoryPricePriceTarget.value)
    if (category === '' || !price || price < 0) {
      DomManipulator.show(this.categoryPriceErrorMessageTarget)
      return
    }

    this.categoryPriceCategoryTarget.value = ''
    this.categoryPricePriceTarget.value = ''
    DomManipulator.hide(this.categoryPriceErrorMessageTarget)

    let newItemTemplate = this.newCategoryPriceTemplateTarget.innerText
      .replace(/{{category}}/g, category.toString())
      .replace(/{{price_per_unit}}/g, price.toString())
      .replace(/{{index}}/g, this.categoriesWithPrice.length.toString())

    DomManipulator.append(newItemTemplate, this.categoryPricesListTarget)

    this.categoriesWithPrice.push(category)

    this.filterOptionsForCategoryPrice()
  }

  deleteCategoryPrice(event) {
    const target = event.target.closest('button')
    const category = target.getAttribute('data-category')

    this.categoriesWithPrice = _.without(this.categoriesWithPrice, category)
    const element = target.closest('li')
    DomManipulator.remove(element)
    this.filterOptionsForCategoryPrice()
  }
}
