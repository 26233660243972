import { Controller } from 'stimulus'
import Dom from '../dom/manipulator'
import Ajax from '../http/ajax'
import _ from 'underscore'

export default class extends Controller {
  static targets = [
    'editBudgetInputPop',
    'editCountryBudgetInput',
    'editBudgetRequiredMessage',
    'editCountryBudget',
    'editCountryName',
    'editBudgetBadge'
  ]

  countryBudgetKeyDown(event) {
    if (event.keyCode === 13) {
      event.preventDefault()
      this.addCountry()
    }
  }

  addCountry() {
    const bundleFilter = this.getControllerByIdentifier('bundle-filter')
    const budget = this.editCountryBudgetInputTarget.value
    if (_.isEmpty(budget) || _.isUndefined(budget)) {
      Dom.show(this.editBudgetRequiredMessageTarget)
      return
    }
    this.editCountryBudgetTarget.value = this.editCountryBudgetInputTarget.value
    Dom.hide(this.editBudgetRequiredMessageTarget)
    Dom.hide(this.editBudgetInputPopTarget)
    const countryToAdd = this.countryToAddValue()
    this.editCountryBudgetInputTarget.value = null

    let formData = new FormData()

    formData.append('iso2', countryToAdd)
    formData.append('budget', budget)

    Ajax.post(
      '/bundles/country_budget',
      formData,
      response => {
        this.updateResponseValue(response.html)
        bundleFilter.setCountryOptionVisibility(countryToAdd, false)
        bundleFilter.filterFormChanged()
      },
      err => {
        console.log(err)
      },
      this
    )
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier
    })
  }

  updateResponseValue(responseHtml) {
    this.editBudgetBadgeTarget.innerHTML = responseHtml
  }

  countryToAddValue() {
    return this.editCountryNameTarget.value
  }
}
