import {Controller} from "stimulus"
import {_} from 'underscore'
import UIkit from 'uikit';


export default class extends Controller {
  connect() {
    this.modalIdentifier = this.data.get('modalIdentifier')
  }


  open() {
    UIkit.modal($("#" + this.modalIdentifier)).show()

    $("#" + this.modalIdentifier).trigger('modal-open:' + this.modalIdentifier) //todo: bad solution. but no time...
  }

}