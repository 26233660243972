import { Controller } from 'stimulus'
import { _ } from 'underscore'
import DomManipulator from '../dom/manipulator'
import Rails from 'rails-ujs'

export default class extends Controller {
  connect() {
    Rails.delegate(
      this.element,
      'form[data-target="seller-game-order.approveForm"]',
      'ajax:success',
      event => {
        this.gameOrderHandled(event.detail[0])
      }
    )

    Rails.delegate(
      this.element,
      'form[data-target="seller-game-order.rejectForm"]',
      'ajax:success',
      event => {
        this.gameOrderHandled(event.detail[0])
      }
    )
  }

  gameOrderHandled(eventData) {
    const gameRow = DomManipulator.find('#gameRow' + eventData.gameId)
    if (eventData.noMoreOrders == true) {
      DomManipulator.remove(gameRow)
    } else {
      DomManipulator.replace(gameRow, eventData.gameView)
    }
    if (eventData.emptyState) {
      this.targets.find('orderSection').innerHTML = eventData.emptyState
    } else if (
      this.targets.find('orderTitle') &&
      eventData.noMorependingOrder
    ) {
      DomManipulator.remove(this.targets.find('orderTitle'))
    } else if (this.targets.find('bidTitle') && eventData.noMorependingBids) {
      DomManipulator.remove(this.targets.find('bidTitle'))
    }

    if (_.isString(eventData.sellerStats)) {
      this.targets.find('statsSection').innerHTML = eventData.sellerStats
    }
  }
}
