import {Controller} from "stimulus"
import {_} from 'underscore'
import ModalFormController from "./modal_form_controller"
import UIkit from "uikit";


export default class extends ModalFormController {

  connect() {
    super.connect()

    this.onNameChange()
  }

  onNameChange() {
    if (this.nameInput.value == "") {
      this.disableSave()
    } else {
      this.enableSave()
    }
  }

  get nameInput() {
    return this.targets.find('nameInput')
  }

  close() {
    super.close()

    $(this.modal).trigger('campaign-creation:cancelled')

  }

  get modal() {
    return this.targets.find('modal')
  }
}