import { Controller } from 'stimulus'
import Ajax from '../http/ajax'
import Dom from '../dom/manipulator'
import UIkit from 'uikit'
export default class extends Controller {
  static targets = [
    'campaignId',
    'gameId',
    'gameInfo',
    'backButton',
    'gameBuyDetails',
    'advertiserId',
    'spinner',
    'hideButton',
    'gameDetails',
    'playerList',
  ]

  connect() {
    this.addMapEventListner = this.addMapEventListner.bind(this)
    this.addBuyDetails = this.addBuyDetails.bind(this)
    this.beforeButtonHide = this.beforeButtonHide.bind(this)
    google.load('visualization', '1.0', {
      packages: ['geochart'],
      callback: this.addMapEventListner,
    })
    this.hideButtonTarget.addEventListener('beforehide', this.beforeButtonHide)
  }

  beforeButtonHide() {
    Dom.show(this.gameDetailsTarget)
    Dom.hide(this.playerListTarget)
  }

  addMapEventListner() {
    this.chart = Chartkick.charts['fans-map']
    google.visualization.events.addListener(
      this.chart.chart,
      'regionClick',
      this.addBuyDetails
    )
  }

  addBuyDetails(event) {
    Dom.show(this.spinnerTarget)
    let formData = new FormData()
    formData.append('campaign_id', this.campaignIdTarget.value)
    formData.append('id', this.gameIdTarget.value)
    formData.append('advertiser_id', this.advertiserIdTarget.value)
    formData.append('country', event.region)

    Ajax.post(
      '/games/game_buying',
      formData,
      function (response) {
        if (this.hideButtonTarget.hidden) {
          UIkit.toggle(this.hideButtonTarget).toggle()
        }
        Dom.html(this.gameBuyDetailsTarget, response.html)
        Dom.hide(this.spinnerTarget)
        Dom.hide(this.playerListTarget)
        Dom.hide(this.gameInfoTarget)
        Dom.show(this.gameDetailsTarget)
        Dom.show(this.backButtonTarget)
      },
      (err) => {
        console.log(err)
      },
      this
    )
  }
}
