import { _ } from 'underscore'
import UIkit from 'uikit'

export default class {
  bind(form, success, error = null, before = null) {
    form.addEventListener('ajax:success', success)

    if (error == null) {
      // Add default error handler
      error = event => {
        const errors = event.detail[0].errors
        if (_.isArray(errors)) {
          const message = errors.join('<br/>')
          UIkit.modal.alert(message)
        }
      }
    }

    form.addEventListener('ajax:error', event => error(event))

    if (before != null) {
      form.addEventListener('ajax:before', event => before(event))
    }
  }
}
