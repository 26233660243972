import {Controller} from "stimulus"
import {_} from 'underscore'
import Rails from "rails-ujs"
import DomManipulator from "../dom/manipulator";

export default class extends Controller {
  static targets = ['creativeFormInput', 'creativeList', 'creativeListItem', 'selectedFileName', 'uploadSection']

  connect() {
    $("#advertiserCreativesForm" + this.data.get('game-order-id')).bind('media-upload:end', $.proxy(this.onCreativeUploaded, this))
  }

  onCreativeUploaded(event) {
    this.appendUploadedCreative(event)
    this.selectNewCreative(event);
  }


  appendUploadedCreative(event) {
    var preview = event.preview
    DomManipulator.prepend(preview, this.creativeListTarget)
  }

  selectNewCreative(event) {
    let creativeListElement = DomManipulator.find('li', this.uploadSectionTarget)
    this.selectCreative(creativeListElement)
  }

  selectCreative(creativeListElement) {
    DomManipulator.addClass(creativeListElement, 'selected')
    this.creativeFormInputTarget.value = creativeListElement.getAttribute("data-creative-id")
    this.selectedFileNameTarget.textContent = creativeListElement.getAttribute("date-file-name")
  }

  creativeSelected(event) {
    let creativeListElement = event.currentTarget
    this.creativeListItemTargets.forEach((item) => DomManipulator.removeClass(item, 'selected'))
    this.selectCreative(creativeListElement)
  }


}