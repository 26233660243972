import { Controller } from 'stimulus'
import DomManipulator from '../dom/manipulator'

export default class extends Controller {
  connect() {
    $('#uploadPhotos' + this.data.get('game-order-id')).bind(
      'media-upload:end',
      $.proxy(this.onPhotosUploaded, this)
    )
  }

  onPhotosUploaded() {
    Turbolinks.clearCache()
    DomManipulator.reloadWithTurbolinks()
  }
}
