import { Controller } from 'stimulus'
import Dom from '../dom/manipulator'
import UIkit from 'uikit'

export default class extends Controller {
  static targets = ['counter']

  activeBellIcon() {
    Dom.addClass(this.bellIcon, 'active')
    UIkit.util.animate(this.bellIcon, 'uk-animation-fade').then(() => {
      Dom.removeClass(this.bellIcon, 'inactive')
      if (this.hasCounterTarget) Dom.hide(this.counterTarget)
    })
  }

  inactiveBellIcon() {
    Dom.addClass(this.bellIcon, 'inactive')
    UIkit.util.animate(this.bellIcon, 'uk-animation-fade').then(() => {
      Dom.removeClass(this.bellIcon, 'active')
    })
  }

  get bellIcon() {
    return this.data.element.querySelector('a.bell-icon')
  }
}
