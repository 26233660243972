import { Controller } from 'stimulus'
import _ from 'underscore'

export default class extends Controller {
  static targets = ['input', 'selectInput', 'form', 'submitButton']

  connect() {
    _.each(this.inputTargets, input =>
      input.addEventListener('input', () => {
        this.submit()
      })
    )

    _.each(this.selectInputTargets, input =>
      input.addEventListener('change', () => {
        this.submit()
      })
    )
  }

  submit() {
    this.submitButtonTarget.click()
  }
}
