import {Controller} from "stimulus"
import {_} from 'underscore'
import Rails from 'rails-ujs'
import UIkit from 'uikit';

import FormSubmissionController from "./form_submission_controller"
import DomManipulator from "../dom/manipulator"

export default class extends Controller {

  static targets = [
    'destroyer'
  ]

  markDestroyed(event) {
    this.destroyerTarget.value = true;
  }

}
