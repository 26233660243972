import { Controller } from 'stimulus'
import DomManipulator from '../../dom/manipulator'

export default class extends Controller {
  static targets = ['idInput', 'titleInput', 'title']
  connect() {}

  initialize() {
    if (this.data.get('builder-id') !== '') {
      this.idInputTarget.value = this.data.get('builder-id')
    }
    if (this.data.get('builder-title') !== '') {
      this.setTitle(this.data.get('builder-title'))
    }
  }

  setTitle(title) {
    this.titleInputTarget.value = title
    this.titleTarget.innerHTML = title
  }

  toggleExlusivity() {
    if (this.exclusiveInput.value.toLowerCase() === 'false') {
      this.exclusivityText.innerHTML = 'Exclusive'
      this.toggleExclusivityText.innerHTML = 'Make non-exclusive'
      this.exclusiveInput.value = true
    } else {
      this.exclusivityText.innerHTML = 'Non-exclusive'
      this.toggleExclusivityText.innerHTML = 'Make exclusive'
      this.exclusiveInput.value = false
    }
  }

  delete() {
    if (this.data.get('builder-html') !== '') {
      DomManipulator.prepend(
        decodeURI(this.data.get('builder-html')),
        document.getElementById('regions')
      )
    }
    DomManipulator.remove(this.element)
  }

  get exclusiveInput() {
    return this.targets.find('exclusiveInput')
  }

  get exclusivityText() {
    return this.targets.find('exclusivityText')
  }

  get toggleExclusivityText() {
    return this.targets.find('toggleExclusivityText')
  }
}
