import { Controller } from 'stimulus'
import { _ } from 'underscore'
import DomManipulator from '../../dom/manipulator'

export default class extends Controller {
  static targets = []

  keyPressed(event) {
    var keyCode = event.keyCode || event.which
    if (keyCode === 13) {
      event.preventDefault()
      this.inputChanged(event)
      return false
    }
  }

  mouseUp(event) {
    this.inputChanged(event)
  }

  inputChanged(event) {
    const input = event.target
    if (input.value === '') {
      this.clearValidations(input)
      return
    }
    let optionFound = false
    const datalist = input.list

    const relevantRegion = _.find(
      datalist.options,
      option => input.value === option.value
    )
    let regionValue = null
    if (relevantRegion !== undefined) {
      optionFound = true
      regionValue = relevantRegion.getAttribute('data-value')
    }

    if (optionFound) {
      this.clearValidations(input)
      this.addNewRegion(input.value, regionValue, relevantRegion.outerHTML)
      relevantRegion.parentNode.removeChild(relevantRegion)
      input.value = ''
    } else {
      input.setCustomValidity('Please select a valid value.')
    }
  }

  clearValidations(input) {
    input.setCustomValidity('')
  }

  addNewRegion(name, value, html) {
    const builderParams = { title: name, id: value, html: html }
    let newItemTemplate = document
      .getElementById('region_template')
      .innerText.replace(/NEW_REGION/g, value)
      .replace(
        'data-components--region-builder-title=""',
        `data-components--region-builder-title="${builderParams.title}"`
      )
      .replace(
        'data-components--region-builder-id=""',
        `data-components--region-builder-id="${builderParams.id}"`
      )
      .replace(
        'data-components--region-builder-html=""',
        `data-components--region-builder-html="${encodeURI(
          builderParams.html
        )}"`
      )
    DomManipulator.append(
      newItemTemplate,
      document.getElementById('regionList')
    )
  }
}
