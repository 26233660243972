import Dom from '../dom/manipulator'
import { Controller } from 'stimulus'
import _ from 'underscore'
import UIkit from 'uikit'
import Ajax from '../http/ajax'

export default class extends Controller {
  static targets = [
    'countriesSelect',
    'countriesSelectContainer',
    'countriesBudgetStrip',
    'filterInput',
    'countryLabel',
    'audienceErrorMessageContainer',
    'audienceIcon',
    'form',
    'fromDate',
    'toDate',
    'budgetInputPop',
    'countryBudgetInput',
    'budgetRequiredMessage',
    'countryBudget',
    'countryName',
    'budgetBadge'
  ]

  connect() {
    this.state = this.data.get('state')
    this.pageState = this.data.get('page-state')
    this.throttledSubmit = _.throttle(this.submitForm, 1000)
    this.updateDateLimits()
    this.hideSelectedCountries()
    this.hideToolTipCallBack = this.hideToolTip.bind(this)
  }

  disconnect() {
    document.body.removeEventListener('click', this.hideToolTipCallBack)
  }

  hideToolTip(event) {
    // Hide the tooltip when click outside the tooltip.
    if (
      this.hasBudgetInputPopTarget &&
      !event.target.closest('.stads-small-tooltip')
    ) {
      Dom.hide(this.budgetInputPopTarget)
      this.countriesSelectTarget.value = ''
      this.countryBudgetInputTarget.value = null
      document.body.removeEventListener('click', this.hideToolTipCallBack)
    }
  }

  countrySelected() {
    Dom.removeClass(this.countriesSelectContainerTarget, 'field_with_errors')
    const selectedOption = this.countriesSelectTarget.options[
      this.countriesSelectTarget.selectedIndex
    ]
    this.countryNameTarget.innerText = selectedOption.text

    Dom.show(this.budgetInputPopTarget)
    UIkit.util
      .animate(this.budgetInputPopTarget, 'uk-animation-fade')
      .then(() => {
        this.countryBudgetInputTarget.focus()
        document.body.addEventListener('click', this.hideToolTipCallBack)
      })
  }

  hideSelectedCountries() {
    _.each(this.budgetBadgeTargets, countryBadge => {
      const countryCode = Dom.find('input[name="countries[]iso2"', countryBadge)
        .value
      this.setCountryOptionVisibility(countryCode, false)
    })
  }

  removeCountry(event) {
    const countryBadge = event.target.parentNode.parentNode
    const countryCode = Dom.find('input[name="countries[]iso2"', countryBadge)
      .value

    Dom.removeImmidiate(countryBadge)
    this.setCountryOptionVisibility(countryCode, true)

    this.filterFormChanged()
  }

  setCountryOptionVisibility(countryCode, visible) {
    _.each(this.countriesSelectTarget.options, option => {
      if (option.value == countryCode) {
        visible ? Dom.show(option) : Dom.hide(option)
      }
    })
  }

  countryBudgetKeyDown(event) {
    if (event.keyCode === 13) {
      event.preventDefault()
      this.addCountry()
    }
  }

  addCountry() {
    const budget = this.countryBudgetInputTarget.value
    if (_.isEmpty(budget) || _.isUndefined(budget)) {
      Dom.show(this.budgetRequiredMessageTarget)
      return
    }
    document.body.removeEventListener('click', this.hideToolTipCallBack)
    Dom.hide(this.budgetRequiredMessageTarget)
    Dom.hide(this.budgetInputPopTarget)
    Dom.hide(this.audienceErrorMessageContainerTarget)
    const countryToAdd = this.countryToAddValue()
    this.countriesSelectTarget.value = ''
    this.countryBudgetInputTarget.value = null

    let formData = new FormData()

    formData.append('iso2', countryToAdd)
    formData.append('budget', budget)

    Ajax.post(
      '/bundles/country_budget',
      formData,
      function(response) {
        console.log('callback')
        console.log(response)
        this.updateResponseValue(response.html)
        this.setCountryOptionVisibility(countryToAdd, false)
        this.filterFormChanged()
      },
      err => {
        console.log(err)
      },
      this
    )
  }

  filterFormChanged() {
    this.updateDateLimits()
    if (this.pageState === 'results' && this.validateForm()) {
      this.throttledSubmit()
    }
  }

  updateDateLimits() {
    this.toDateTarget.setAttribute('min', this.fromDateTarget.value)
  }

  validateForm() {
    const inputsFilled = _.every(this.formInputTargets, input => {
      return input.value !== null && input.value !== undefined
    })
    return inputsFilled
  }

  submitForm() {
    this.formTarget.submit()
  }

  setWithCountriesState(firstCountry) {
    this.state = 'with-countries'
    Dom.remove(this.countryLabelTarget)
    Dom.addClass(this.element, 'with-countries')

    UIkit.util
      .animate(
        this.countriesSelectContainerTarget,
        'uk-animation-fade uk-animation-reverse'
      )
      .then(() => {
        _.each(this.filterInputTargets, input =>
          Dom.addClass(input, 'with-countries')
        )
        Dom.show(this.audienceIconTarget)
        this.countriesBudgetStripTarget.innerHTML += firstCountry
        Dom.move(
          this.countriesSelectContainerTarget,
          this.countriesBudgetStripTarget
        )
        UIkit.util.animate(
          this.countriesBudgetStripTarget,
          'uk-animation-fade '
        )
      })
  }

  isCountryBudgetController() {
    return false
  }

  updateResponseValue(responseHtml) {
    if (this.state !== 'with-countries') {
      this.setWithCountriesState(responseHtml)
    } else {
      const elem = Dom.insertBefore(
        responseHtml,
        this.countriesSelectContainerTarget
      )
      UIkit.util.animate(elem, 'uk-animation-fade')
    }
  }

  countryToAddValue() {
    return this.countriesSelectTarget.value
  }
}
