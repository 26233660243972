import {Controller} from "stimulus"
import {_} from 'underscore'
import Rails from 'rails-ujs'
import UIkit from 'uikit';

import FormSubmissionController from "./form_submission_controller"
import DomManipulator from "../dom/manipulator"

export default class extends FormSubmissionController {

  static targets = [
    'pfTokenInput',
    'bluesnapJs',
    'creditCardRadioButton',
    'sepaRadioButton',
    'accordion',
    'creditCardSection',
    'sepaSection',
    'wireSection',
    'pmTypeInput',
    'ibanInput',
    'ccfirstNameInput',
    'cclastNameInput',
    'sepafirstNameInput',
    'sepalastNameInput'
  ]

  connect() {
    super.connect()


    let token = this.pfTokenInputTarget.value

    if (window.bluesnap === undefined) {
      this.bluesnapJsTarget.addEventListener('load', () => {
        bluesnap.hostedPaymentFieldsCreation(token, this.bsConf);
      });
    } else {
      bluesnap.hostedPaymentFieldsCreation(token, this.bsConf);
    }
    this.creditCardSectionTarget.addEventListener('show', (event) => {
        this.pmTypeInputTarget.value = 'CreditCard'
        this.toggleRequiredFields()
        this.updateNameInputs()
      }
    )

    this.sepaSectionTarget.addEventListener('show', (event) => {
        this.pmTypeInputTarget.value = 'Sepa'
        this.toggleRequiredFields()
      this.updateNameInputs()
      }
    )

    this.wireSectionTarget.addEventListener('show', (event) => {
        this.pmTypeInputTarget.value = 'Wire'
        this.toggleRequiredFields()
      }
    )

    this.activeFirstNameInputName = this.ccfirstNameInputTarget.name
    this.activeLastNameInputName = this.cclastNameInputTarget.name
    this.updateNameInputs()
  }

  get paymentMethodType(){
    return this.pmTypeInputTarget.value
  }

  updateNameInputs(){
    switch (this.paymentMethodType) {
      case 'CreditCard': {
        this.ccfirstNameInputTarget.name = this.activeFirstNameInputName
        this.cclastNameInputTarget.name = this.activeLastNameInputName
        this.sepafirstNameInputTarget.name = 'inactiveF'
        this.sepalastNameInputTarget.name = 'inactiveL'
        break
      }
      case 'Sepa': {

        this.sepafirstNameInputTarget.name = this.activeFirstNameInputName
        this.sepalastNameInputTarget.name = this.activeFirstNameInputName
        this.cclastNameInputTarget.name =  'inactiveF'
        this.cclastNameInputTarget.name = 'inactiveL'
      }
    }
  }
  toggleRequiredFields() {
    const paymentMethodType = this.paymentMethodType
    this.ccfirstNameInputTarget.removeAttribute('required')
    this.cclastNameInputTarget.removeAttribute('required')
    this.sepafirstNameInputTarget.removeAttribute('required')
    this.sepalastNameInputTarget.removeAttribute('required')
    this.ibanInputTarget.removeAttribute('required')

    switch (paymentMethodType) {
      case 'CreditCard': {
        this.ccfirstNameInputTarget.required = 'required'
        this.cclastNameInputTarget.required = 'required'
        break
      }
      case 'Sepa': {
        this.ibanInputTarget.required = 'required'
        this.sepafirstNameInputTarget.required = 'required'
        this.sepalastNameInputTarget.required = 'required'
      }
    }
  }

  get bsConf() {

    return {
      onFieldEventHandler: {
        // tagId returns: "ccn", "cvv", "exp"
        onFocus: function (tagId) {
        }, // Handle focus
        onBlur: function (tagId) {
        }, // Handle blur
        onError: function (tagId, errorCode /*, errorDescription*/) {
        }, // Handle a change in validation
        /*errorCode returns:
            "001" --> "Please enter a valid credit card number";
            "002" --> "Please enter the CVV/CVC of your card";
            "003" --> "Please enter your credit card's expiration date";
            "22013" --> "CC type is not supported by the merchant";
            "14040" --> " Token is expired";
            "14041" --> " Could not find token";
            "14042" --> " Token is not associated with a payment method, please verify your client integration or contact BlueSnap support";
            "400" --> "Session expired please refresh page to continue";
            "403", "404", "500" --> "Internal server error please try again later";
        */

        /* errorDescription is optional. Returns BlueSnap's standard error description */

        onType: function (tagId, cardType, cardData) {
          /* cardType will give card type, and only applies to ccn: CarteBleue, Visa, MasterCard, AmericanExpress, Discover, DinersClub, JCB, Solo, MaestroUK, ChinaUnionPay */
          if (null != cardData) {
            /* cardData is an optional parameter which will provide ccType, last4Digits, issuingCountry, isRegulatedCard, cardSubType and binCategory details (only applies to ccn) in a JsonObject */
            console.log(cardData);
          }
        },

        onValid: function (tagId) {
        }, // Handle a change in validation
      },
      style: {
        "span": {
          color: 'transparent',
          left: '20px',
          height: '20px'
          // top: '12px'
        }
      },
      expDropDownSelector: false,
      expPlaceHolder: "MM      YY"
    }

    //Run the following command after Document Object Model (DOM) is fully loaded

    /* example:
    / style: {
    / Style elements
    / (Selectors: "#ccn", "#cvv", "#year", "#month", "input", "::placeholder", ":focus", ".valid", ".invalid", "span", "select", "option")
    /     (Properties: "color", "font", "font-family", "font-size", "font-style", "font-weight", "line-height", "opacity", "outline", "text-shadow", "transition",
    /     "left", "margin-right", "width", "height", "background-color)
    /       "Selector": {
    /       "Property": "Value",
    /       "Property2": "Value2"
    /       },
    /       "Selector2": {
    /       "Property": "Value"
    /       }
    /       },
    /     style: {
    /       ":focus": {
    /         //style for all input elements on focus event
    /         "color": "orange"
    /       },
    /       "input": {
    /         //style for all input elements
    /         "color": "blue"
    /       },
    /       ".invalid": {
    /         //style for all input elements when invalid
    /         "color": "red"
    /       }
    /     },
    /     ccnPlaceHolder: "1234 5678 9012 3456", //for example
    /     cvvPlaceHolder: "123", //for example
    /     expPlaceHolder: "MM/YY", //for example
    /     expDropDownSelector: false //set to true for exp. date dropdown
    /   }; */

  }

  submit(event) {
    event.preventDefault();

    switch (this.paymentMethodType) {
      case 'CreditCard':{
        bluesnap.submitCredentials((callback) => {
          if (null != callback.cardData) {
            console.log('the card type is ' + callback.cardData.ccType +
              ', last 4 digits are ' + callback.cardData.last4Digits +
              ', exp is ' + callback.cardData.exp + ' and issuing Country is ' +
              callback.cardData.issuingCountry + ', after that I can call final submit');
            super.submit()
          } else {
            var errorArray = callback.error;
            for (var i in errorArray) {
              console.log("Received error: tagId= " +
                errorArray[i].tagId + ", errorCode= " +
                errorArray[i].errorCode + ", errorDescription= " +
                errorArray[i].errorDescription);
            }
          }
        });
        break
      }
      case 'Sepa':{
        super.submit()
        break
      }
      case 'Wire':{
        super.submit()
        break
      }
    }
  }
}
