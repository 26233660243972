import { Controller } from 'stimulus'
import { _ } from 'underscore'
import CableReady from 'cable_ready'

/**
 * Live updates - handles cable ready related html morphs
 */
export default class extends Controller {
  connect() {
    if (!_.isNull(this.channelName) && !_.isUndefined(this.channelName)) {
      this.channel = App.cable.subscriptions.create(
        { channel: this.channelName, room: this.room },
        {
          received: function (data) {
            if (data.cableReady) {
              CableReady.perform(data.operations)
            }
          },
        }
      )
    }
  }

  initialize() {
    const split = this.data.get('channel').split('_')
    this.channelName = split[0]
    this.room = split[1]
  }

  disconnect() {
    if (_.isUndefined(this.channel)) {
      this.channel.unsubscribe()
    }
  }
}
