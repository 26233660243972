import UIkit from 'uikit';
import DomManipulator from "../dom/manipulator"
import Rails from "rails-ujs"
import {_} from 'underscore'

export default class {


  bind(element) {
    const errorWrapperClassName = 'field_with_errors'
    const errorMessageSelector = '.error-message'

    Rails.delegate(element, "input, select", "change", (event) => {
      const input = event.target
      const parent = input.parentElement

      if (_.isElement(parent)) {
        if (DomManipulator.hasClass(parent, errorWrapperClassName)) {
          DomManipulator.removeClass(parent, errorWrapperClassName)
          const errorWrapperParent = parent.parentElement
          const errorMessageElement = DomManipulator.find(errorMessageSelector, errorWrapperParent)

          if (_.isElement(errorMessageElement)) {
            DomManipulator.hide(errorMessageElement)
          }
        } else {
          const errorMessageElement = DomManipulator.find(errorMessageSelector, parent)
          if (_.isElement(errorMessageElement)) {
            DomManipulator.hide(errorMessageElement)
          }
        }
      }
    })
  }
}