import {Controller} from "stimulus"
import {_} from 'underscore'
import MediaUploadController from "./model_media_upload_base_controller"

import Rails from "rails-ujs";

export default class extends MediaUploadController {

  initData() {
    super.initData();

    this.modelId = this.data.get('modelId')
    this.formActionBase = '/media/' + this.modelId + '/'

    this.onMediaRemovedEventListener = $.proxy(this.onMediaRemoveSuccessEvent, this)
    this.onMediaRemovedFailureEventListener = $.proxy(this.onMediaRemoveFailureEvent, this)
  }


  beginFileUpload() {
    $(this.mediaFormElement).attr('action', this.formActionBase + this.getFormFileUploadAction()); //todo: ugly

    super.beginFileUpload();
  }

  renderInitialState() {
    super.renderInitialState()
  }

  getFormFileUploadAction() {
    return this.data.get('hasMedia') === 'false' ? 'add_media' : 'update_media'
  }

  remove() {
    $(this.root).trigger("media-upload:start")

    this.subscribeToMediaRemovalEvents();

    this.disableRemoveMediaButton();

    $(this.mediaFormElement).attr('action', this.formActionBase + 'remove_media'); //todo: ugly
    Rails.fire(this.mediaFormElement.get(0), 'submit')
  }

  subscribeToMediaRemovalEvents() {
    $(this.mediaFormElement).bind('ajax:success', this.onMediaRemovedEventListener)
    $(this.mediaFormElement).bind('ajax:error', this.onMediaRemovedFailureEventListener)
  }

  enableRemoveMediaButton() {
    $(".remove-media").prop("disabled", false)
    $(".remove-spinner").addClass("uk-hidden")
  }

  disableRemoveMediaButton() {
    $(".remove-media").prop("disabled", true)
    $(".remove-spinner").removeClass("uk-hidden")
  }

  onMediaRemoveSuccessEvent() {
    this.unsubscribeFromMediaRemoveEvents()

    this.renderSelectorState()

    this.enableRemoveMediaButton()

    $(this.root).trigger("media-upload:end")
  }

  onMediaRemoveFailureEvent() {
    this.unsubscribeFromMediaRemoveEvents()

    this.enableRemoveMediaButton()

    //todo: what should I show here? nothing?

    $(this.root).trigger("media-upload:end")
  }


  onServerSuccessEvent(event) {
    this.unsubscribeFromServerResponseEvents()

    this.renderSelectorState()

    var preview = event.originalEvent.detail[0].pageHtml
    $(this.root).trigger({type: "media-upload:end", preview: preview})

  }

  onServerFailureEvent(event) {
    this.unsubscribeFromServerResponseEvents()

    this.renderSelectorState()

    $(this.root).trigger({type: "media-upload:end", preview: null})

    //todo: should probably show upload failure message
  }

  unsubscribeFromMediaRemoveEvents() {
    $(this.mediaFormElement).off('ajax:success', this.onMediaRemovedEventListener)
    $(this.mediaFormElement).off('ajax:error', this.onMediaRemovedFailureEventListener)
  }
}
